import { createApp } from 'vue';
import router from './router';
import store from '@/stores';
import vuetify from './plugins/vuetify';
import i18n from '@/i18n';
import App from './App.vue';
import { loadFonts } from './plugins/webfontloader';

// components
import WebsiteLayout from '@/components/WebsiteLayout.vue';
import PeripheralsSelect from '@/components/PeripheralsSelect.vue';

import './interceptors/axios';

loadFonts();

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(store);
app.use(i18n);

app.component('WebsiteLayout', WebsiteLayout);
app.component('PeripheralsSelect', PeripheralsSelect);

router
  .isReady()
  .then(() => {
    app.mount('#app');
  })
  .catch((e) => console.error(e));
