type ENV = {
  mode: string,
  app: {
    baseUrl: string;
    apiBaseUrl: string;
    webstorageNamespace: string;
  };
};

const env: ENV = {
  mode: 'local',
  app: {
    baseUrl: '',
    apiBaseUrl: '',
    webstorageNamespace: '',
  },
};

export default env;
