import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { type SupportedLanguage, FALLBACK_LANGUAGE } from '@/i18n';

type ConfigState = {
  _themeDark: boolean;
  _locale: string;
};

export default defineStore('config', {
  state: (): ConfigState => ({
    _themeDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    _locale:
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      FALLBACK_LANGUAGE,
  }),
  getters: {
    themeDark(state): boolean {
      return state._themeDark;
    },
    locale(state): string {
      return state._locale;
    },
  },
  actions: {
    toggleLightDarkMode() {
      this._themeDark = !this._themeDark;
    },
    setLocale(locale: SupportedLanguage) {
      this._locale = locale;
      location.reload();
    },
    stringLang(arabicString: string | null, englishString: string | null) {
      return this._locale === 'en' || arabicString == null
        ? (englishString as string)
        : arabicString;
    },
  },
  persist: {
    debug: env.mode === 'local',
    key: env.app.webstorageNamespace + '.config.store',
    storage: window.localStorage,
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
});
